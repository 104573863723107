import React from "react"

import BgImage from "../../Image/BgImage"

import { HeroSlide } from "../../../models/common"

interface Props extends HeroSlide {
}

const defaultProps = {}

export const HeroImageSlide: React.FC<Props> = props => {
  return (
    <BgImage
      image={props?.image}
      cssClass="slide__image"
      style={{ backgroundSize: props.objectFit || "contain" }}
    />
  )
}

HeroImageSlide.defaultProps = defaultProps

export default HeroImageSlide
