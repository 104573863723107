import React from "react"
import ReactPlayer from "react-player/lazy"

import { HeroSlide } from "../../../models/common"

interface Props extends HeroSlide {}

const defaultProps = {}

export const HeroVideoSlide: React.FC<Props> = props => {
  return (
    <div className="slide__video">
      <ReactPlayer
        url={props.video_url}
        config={{
          youtube: {
            playerVars: {
              autoplay: 0,
              rel: 0,
            },
          },
        }}
        className="video__player"
      />
    </div>
  )
}

HeroVideoSlide.defaultProps = defaultProps

export default HeroVideoSlide
